.count_box{
    background-color: #fff !important;
    border-radius: 8px !important;
    padding: 15px !important;
    display: flex;
    align-items: center;
    gap: 22px;
    position: relative;
}
.count_box p{
    background-color: #00B07426;
    padding: 14px;
    border-radius: 50%;
}
.count_box h2{
    font-size: 24px;
    font-weight: 600;
}
.count_box h5{
    position: absolute;
    top: 13px;
    right: 17px;
    color: #00CB4F !important;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.count_box h5 svg{
    height: 13px !important;
}
.count_box span{
    font-size: 15px;
}
.no_of_users{
    background-color: #fff !important;
    border-radius: 8px !important;
    padding: 15px 25px !important;
}
.user_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user_head h6{
    font-size: 17px;
    font-weight: 600;
    margin: 0;
    letter-spacing: 0.5px;
}
.sub_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user_percent{
    height: 5px !important;
    background-color: #F4F4F5 !important;
    border-radius: 8px !important;
    margin-top: 6px !important;
}
.user_percent p{
    background-color: #093F40 !important;
    border-radius: 8px !important;
    height: 100%;
}
.chart_part{
    background-color: #fff !important;
    border-radius: 8px !important;
    padding: 15px 5px 15px 25px !important;
    height: 100%;
}
.chart_infos{
    display: grid !important;
    grid-template-columns: 43% 43%;
    justify-content: space-around;
    row-gap: 30px;
}
.single_chart_info{
    display: flex;
    align-items: center;
    gap: 15px;
}
.single_chart_info .single_chart_info_color{
    width: 17px !important;
    height: 17px !important;
    border-radius: 50% !important;
}
.single_chart_info p{
    color: #00CB4F !important;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    /* font-size: 14px; */
}
.single_chart_info p svg{
    height: 12px !important;
}
.single_chart_info h4{
    font-size: 24px;
    font-weight: 600;
}
.datePicker1{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px !important;
    gap: 7px;
}
.dataInputTwo > div {
    width: 275px !important;
    height: 46px !important;
    background-color: #fff !important;
}
.dataInputTwo fieldset{
    border: none !important;
}
.date_clear{
    position: absolute;
    top: 10px;
    right: 9px;
    font-size: 26px;
    color: #ff6c6c !important;
}