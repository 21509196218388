.defaultGuestLayoutMaindiv {
  display: grid;
  grid-template-columns: 55% 45%;
  height: calc(100vh - 60px);
}

.DefaultGuestLayoutRightContainer {
  background-color: var(--default-bg-color);
}

.loginDefaultLayoutChildContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.LoginDivMainContainer {
  width: 60%;
  height: max-content;
}

.LoginMainHeader {
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 38px !important;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text-color-1);
}

.LoginSubHeader {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: var(--text-color-1);
}

.ValidatorForm {
  margin-top: 40px;
  width: 85%;
}

.formLable {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: var(--text-color-1);
}

.loginBtn {
  height: 48px;
  background-color: var(--default-bg-color) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 24px !important;
  text-transform: none !important;
}

.loginBtn:hover {
  color: var(--default-bg-color) !important;
  background-color: var(--white-color) !important;
}

.forgotPasswordBtn {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: right;
  width: 100%;
  color: var(--text-color-1);
  cursor: pointer !important;
}

.formInputFeild > div {
  border-radius: 8px !important;
}

.focusedInput fieldset {
  border-color: var(--focused-inputborder) !important;
  border-width: 1px !important;
}

.modalTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 70%; */
  justify-content: space-around;
  height: 100%;
}

.eyeIcon {
  margin-right: 0px !important;
}

.helperText {
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: var(--text-color-1);
}

.errorInput input {
  color: var(--error-colors) !important;
}

.closebtn {
  position: absolute !important;
  top: 0;
  right: 0;
  cursor: pointer !important;
  color: white !important;
  z-index: 22;
}

.successModalMainHeading {
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: center;
  width: 75%;
}

.loginModalWidth {
  transform: translate(0%, -20%) !important;
  max-width: 75vw !important;
  max-height: 80vh !important;
}

.loginModalWidth .videoModal {
  height: 100%;
}

.successModalSubHeading {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: center;
  width: 60%;
}

.confirmationModalBtnSection {
  display: flex;
  column-gap: 20px !important;
}

.cancelBtn {
  color: var(--default-bg-color) !important;
  border: 1px solid var(--default-bg-color) !important;
  height: 30px;
  border-radius: 6px !important;
  text-transform: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px !important;
  text-align: left;
}

.confirmBtn {
  height: 30px;
  border-radius: 6px !important;
  background-color: var(--default-bg-color) !important;
  color: var(--white-color) !important;
  text-transform: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px !important;
  text-align: left;
}
