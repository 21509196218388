.AddPropertyMainCard {
  padding: 20px 30px !important;
}

.AddPropertyHeader {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: var(--default-bg-color);
}

.AddPropertySubHeader {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: var(--sub-heading-color);
}

.propertyTypeChip {
  height: 40px !important;
  background-color: var(--default-bg-color-1);
  padding-left: 15px !important;
  color: var(--default-bg-color) !important;
}
.PropertyActiveChip {
  background-color: var(--default-bg-color) !important;
  color: var(--white-color) !important;
}

.PropertyActiveChip path {
  fill: var(--white-color) !important;
}

.AddPropertyInputLable {
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

.basicDetailsInputContainer {
  display: flex;
  column-gap: 50px !important;
  align-items: center;
}

.formInputContainer {
  display: flex;
  column-gap: 20px !important;
  align-items: center;
  width: max-content;
}

.imagesContainer {
  width: 100%;
  overflow-x: scroll;
}

.formInputFeild > div,
.selectInput {
  border-radius: 8px !important;
  min-width: 160px !important;
  height: 38px !important;
  max-width: 200px !important;
}
.formInputFeild1 {
  width: 100% !important;
}

.formInputFeild1 > div {
  border-radius: 8px !important;
  min-width: 100% !important;
}
.formInputFeild2 > div,
.selectInput {
  border-radius: 8px !important;
  min-width: 160px !important;
  height: 38px !important;
}
.AddPropertyImages {
  height: 150px !important;
}

.formInputContaine1 {
  width: 100% !important;
  align-items: flex-start !important;
}

.focusedInput fieldset {
  border-color: var(--focused-inputborder) !important;
  border-width: 1px !important;
}

.LocationLable {
  min-width: 120px !important ;
}

.LocationContainer {
  /* column-gap: 60px !important; */
}
.overflowClass {
  width: 100%;
  overflow-x: scroll !important;
  /* height: 50px !important; */
}

.overflowClass::-webkit-scrollbar {
  display: none;
}

.pricingDDetailsBtn {
  height: 38px !important;
  border-radius: 5px !important;
  border: 1px solid var(--sub-checkbox-btn-color) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19.09px !important;
  text-align: left !important;
  text-transform: none !important;
  color: var(--defaullt-black) !important;
  min-width: max-content !important;
  display: flex !important;
  column-gap: 10px !important;
  align-items: center;
}

.pricingDDetailsBtnActive {
  color: var(--white-color) !important;
  background-color: var(--default-bg-color) !important;
  border-color: var(--default-bg-color) !important;
}

.pricingDDetailsBtnActiveIcon {
  color: var(--white-color) !important;
}

.formInputContaine1 p + div {
  width: 100% !important;
}

.propertyImageUploadContainer {
  height: 166px !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  background-color: #093f401a !important;
  border-radius: 11px;
}

.ImageNameContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ImageNameTag {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  letter-spacing: 0.5px !important;
  text-align: left !important;
  color: var(--upload-image-note-text-color) !important;
}

.deleteIcon {
  font-size: 14px !important;
  color: var(--defaullt-bg-color) !important;
  cursor: pointer;
}

.CheckBoxContainer {
  display: flex !important;
  column-gap: 10px !important;
  align-items: center;
  padding: 10px !important;
}

.propertyImageUploadBtn {
  height: 31px !important;
  background-color: var(--default-bg-color) !important;
  color: var(--white-color) !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.5px !important;
  text-align: left;
  text-transform: none !important;
}

.propertyImageUploadText {
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.5px !important;
  text-align: left;
  color: var(--upload-image-note-text-color) !important;
}

.formBtnContainer {
  display: flex;
  column-gap: 20px !important;
}
.formSubmitBtn {
  width: 222px;
  height: 48px;
  border-radius: 24px !important;
  font-family: SF Pro Display;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.48px;
  text-align: center;
  color: var(--white-color);
  background-color: var(--default-bg-color) !important;
}

.formCancelBtn {
  color: var(--defaullt-bg-color) !important;
  background-color: var(--white-color) !important;
}

.HeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerBtnsContainer {
  display: flex;
  column-gap: 10px;
}

.headerBtn {
  height: 30px !important;
  background-color: var(--default-bg-color) !important;
  color: var(--white-color) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px !important;
  display: flex;
  column-gap: 10px !important;
  text-transform: none !important;
}

.headerBtn svg {
  width: 14px !important;
  height: 14px !important;
}

.checked > svg {
  color: var(--white-color) !important;
}

.checkboxText {
  color: var(--white-color) !important;
}

.NoteText {
  font-size: 12px !important;
}
.location_select{
  /* background-color: #f8f8f8 !important; */
  border:1px solid rgba(0, 0, 0, 0.2) !important;
  text-transform: capitalize !important;
  color: rgba(0, 0, 0, 0.4) !important;
  text-align: start !important;
  display: block !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  width: 200px !important;
}
.location_select_view{
  background-color: #f8f8f8 !important;
  border:1px solid rgba(0, 0, 0, 0.2) !important;
  text-transform: capitalize !important;
  color: rgba(0, 0, 0, 0.4) !important;
  text-align: start !important;
  display: flex !important;
  font-weight: 500 !important;
  align-items: center !important;
  justify-content: space-between !important;
  cursor: default !important;
}
.location_select_view svg{
  font-size: 17px !important;
  color: rgba(0, 0, 0, 0.7) !important;
  cursor: pointer;
}
.carpet_area{
  flex-wrap: wrap !important;
  width: 100%;
  row-gap: 15px !important;
}
.new_countrycode_field{
  height: 41px !important;
}
.new_countrycode_field > div{
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0,0,0,0.01);
  border-radius: 9px;
}
.new_countrycode_field input{
  height: 100%;
  border: none;
  border: 1px solid rgba(0,0,0,0.2) !important;
  border-radius: 8px !important;
}
.new_countrycode_err input{
  height: 100%;
  border: none;
  border: 1px solid rgba(255, 0, 0, 1) !important;
  border-radius: 8px !important;
}
.new_countrycode_err{
  margin-bottom: 13px !important;
}
.new_countrycode_field input:focus {
  /* color: var(--bs-body-color);
  background-color: var(--bs-body-bg); */
  border-color: var(--focused-inputborder) !important;
  /* border-width: 2px !important; */
  /* outline: 0; */
  box-shadow: none !important; 
}
.new_countrycode_field ul{
  width: 100% !important;
  overflow: auto !important;
}
.err_text{
  color: #d32f2f !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}