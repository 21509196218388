.remainder-pop .MuiPaper-root.MuiDialog-paper {
  width: 50% !important;
  padding: 24px;
  position: relative;
}
.close-icon {
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 28px;
  cursor: pointer;
}
.remainder-header {
  font-weight: 600 !important;
  font-size: 19px !important;
  color: var(--text-color-1) !important;
  display: flex;
  align-items: center;
  gap: 10px;
}
.my-field .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
  background-color: #f8f8f8 !important;
}
.my-field
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.3) !important;
}
.my-field .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0 !important;
}
.send-btn{
    background-color:var(--default-bg-color) !important ;
    color: #fff !important;
    text-transform: capitalize !important;
    padding: 4px 15px !important;
    font-size: 17px !important;
    border: 1px solid var(--default-bg-color) !important;
}
.cancel-btn{
    background-color:#fff !important ;
    color: var(--default-bg-color) !important;
    text-transform: capitalize !important;
    /* margin-top: 40px !important; */
    padding: 4px 15px !important;
    font-size: 17px !important;
    border: 1px solid var(--default-bg-color) !important;
}
.google-map .MuiPaper-root.MuiDialog-paper {
  width: 55% !important;
  max-width: 100% !important;
  /* padding: 24px; */
  position: relative;
}
.popup-header{
  background-color: var(--selected-menu-bg) !important;
  color: #fff !important;
  padding: 8px 24px !important;
}
.capture-btn {
  background-color: var(--selected-menu-bg) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border: 1px solid var(--selected-menu-bg) !important;
  font-size: 13px !important;
  padding: 5px 12px !important;
}
.contact-close {
  font-size: 28px;
  cursor: pointer;
}
.clear-btn {
  background-color: #fff !important;
  color: var(--default-bg-color) !important;
  text-transform: capitalize !important;
  border: 1px solid var(--default-bg-color) !important;
  font-size: 13px !important;
  padding: 5px 12px !important;
}
.notification-pop .MuiPaper-root.MuiDialog-paper{
  width: auto !important;
  max-width: 70% !important;
  min-width: 45% !important;
  /* max-width: 100% !important; */
  /* padding: 24px; */
  position: relative;
}
.notification-box{
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3) !important;
  border-radius: 5px;
  padding: 20px;
  padding-bottom: 30px;
  display: flex;
  align-items: start;
  gap: 20px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}
.notification-active{
background-color: #3d6c6d38  !important;
}
.notification-time{
  position: absolute;
  bottom: 7px;
  right: 35px;
  color: black;
  font-weight: 600 !important;
}
.hover-onclose{
position: absolute;
top: 13px;
right: 16px;
font-size: 21px !important;
color: #db5050 !important;
visibility: hidden;

}
.notification-box:hover .hover-onclose{
visibility: visible;
transition: visibility 0.2s;
}