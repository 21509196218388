.userIndexMainHeader {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px !important;
  text-align: left;
  color: var(--default-bg-color);
}

.TableHeaderContainer {
  padding: 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CommonTextInput input::placeholder {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
}

.CommonTextInput > div {
  border-radius: 7px !important;
  height: 38px !important;
}

.TableHeaderLeftContainer,
.TableHeaderRightContainer {
  column-gap: 15px;
  display: flex;
  align-items: center;
}

.countInputDiv {
  border: 1px solid var(--default-border-color);
  column-gap: 10px;
  height: 38px !important;
  width: max-content !important;
  display: flex;
  align-items: center;
  padding: 0 10px !important;
  border-radius: 7px !important;
}

.countText {
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0.20000000298023224px !important;
  text-align: left;
}

.CountIcon {
  padding: 0 !important;
}

.countInput > div {
  width: 40.31px !important;
  height: 22.86px !important;
  border-radius: 3px !important;
}

.countInput input {
  font-family: General Sans !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.20000000298023224px !important;
  text-align: left;
  padding: 0 0 0 5px !important;
}

/* .countInput input::-webkit-outer-spin-button,
.countInput input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

.userFilterBtn {
  display: flex !important;
  column-gap: 10px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  border-radius: 7px !important;
  border: 1px solid var(--default-border-color) !important;
  height: 38px !important;
  color: var(--default-btn-text-color) !important;
  text-transform: none !important;
}

.TableContainer {
  overflow-x: scroll !important;
}

.TableContainer::-webkit-scrollbar {
  display: none;
}

.TableHeaderRow {
  background-color: var(--default-header-row-color) !important;
}

.TableHeaderRow th > span {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  letter-spacing: 0px !important;
  text-align: left;
}

.TableDataRows {
  height: 75px !important;
}

.radioContainer {
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.ActionBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.actionBtn {
  height: 30px;
  border-radius: 6px !important;
  background-color: var(--default-bg-color) !important;
  color: var(--white-color) !important;
  text-transform: none !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: 0.10000000149011612px !important;
  text-align: left;
}

.statusBtn {
  height: 26px;
  padding: 6px 12px;
  border-radius: 8px !important;
  /* font-family: General Sans !important; */
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  letter-spacing: 0px;
  text-align: right;
  text-transform: capitalize !important;
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.addUserForm {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 30% 70%;
  row-gap: 20px;
  width: 50%;
  align-items: center;
}

.filterHeaderdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.clearFilterBtn {
  color: var(--default-bg-color) !important;
  cursor: pointer !important;
}
.sub_header {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
}

.formLable {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: var(--text-color-1);
}

.formInputFeild > div {
  border-radius: 8px !important;
  background-color: var(--white-color) !important;
}

.focusedInput fieldset {
  border-color: var(--focused-inputborder) !important;
  border-width: 1px !important;
}

.commonBtn {
  height: 48px;
  background-color: var(--default-bg-color) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
  letter-spacing: 0em;
  text-align: center;
  border-radius: 24px !important;
  text-transform: none !important;
}

.commonBtn:hover {
  color: var(--default-bg-color) !important;
  background-color: var(--white-color) !important;
}

.search_btn {
  background-color: var(--default-bg-color) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  width: 325px;
  border-radius: 20px !important;
  /* position: absolute !important;
  bottom: 32px;
  right: 50px; */
}
.search_btn svg {
  fill: #fff !important;
}

.clear_fil {
  text-transform: capitalize !important;
  color: var(--default-bg-color) !important;
  background-color: #fff !important;
  border: 1px solid var(--default-bg-color) !important;
  padding: 5px 20px !important;
}

.new_countrycode_field{
  height: 54px !important;
}
.new_countrycode_field > div{
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0,0,0,0.01);
  border-radius: 9px;
}
.new_countrycode_field input{
  height: 100%;
  border: none;
  border: 1px solid rgba(0,0,0,0.2) !important;
  border-radius: 8px !important;
}
.new_countrycode_err input{
  height: 100%;
  border: none;
  border: 1px solid rgba(255, 0, 0, 1) !important;
  border-radius: 8px !important;
}
.new_countrycode_err{
  margin-bottom: 13px !important;
}
.new_countrycode_field input:focus {
  /* color: var(--bs-body-color);
  background-color: var(--bs-body-bg); */
  border-color: var(--focused-inputborder) !important;
  /* border-width: 2px !important; */
  /* outline: 0; */
  box-shadow: none !important; 
}
.new_countrycode_field ul{
  width: 100% !important;
  overflow: auto !important;
}
.err_text{
  color: #d32f2f !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}