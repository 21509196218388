.list_header {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: var(--default-bg-color) !important;
}
.filter_part {
  background-color: #fff !important;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 10px 20px 30px 20px !important;
  margin-top: 24px !important;
  position: relative;
}
.table_part {
  background-color: #fff !important;
  border-radius: 4px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 10px 0px 30px 0px !important;
  margin-top: 24px !important;
  position: relative;
}
.filter_list {
  display: flex;
  align-items: center;
  column-gap: 40px;
  row-gap: 20px;
  flex-wrap: wrap;
}
.search_btn {
  background-color: var(--default-bg-color) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  width: 325px;
  border-radius: 20px !important;
  position: absolute !important;
  bottom: 32px;
  right: 50px;
}
.search_btn svg {
  fill: #fff !important;
}
.sub_header {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
}
.export_btn {
  color: var(--default-btn-text-color) !important;
  background-color: #fff !important;
  border: 1px solid #dfdfdf !important;
  text-transform: capitalize !important;
}
.export_btn svg {
  margin-right: 6px;
  margin-top: -3px;
}
.tableHead {
  background-color: var(--default-header-row-color) !important;
}
.tableHead th {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 6px 16px !important;
}
.table_body td {
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 6px 16px !important;
}
.active_prop {
  color: #0caf60 !important;
  background-color: #e7f7ef !important;
  border-radius: 8px !important;
  padding: 5px 10px;
}
.pending_prop {
  color: #fe964a !important;
  background-color: #fff0e6 !important;
  border-radius: 8px !important;
  padding: 5px 10px;
}
.sold_prop {
  color: #8c62ff !important;
  background-color: #f4f0ff !important;
  border-radius: 8px !important;
  padding: 5px 10px;
}
.deactivate_prop {
  color: #fd6a6a !important;
  background-color: #fff0f0 !important;
  border-radius: 8px !important;
  padding: 5px 10px;
}
.popper_btns {
  color: var(--text-color-1) !important;
  border: 1px solid var(--text-color-1) !important;
  background-color: #fff !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}
.popper_btns svg {
  margin-right: 6px !important;
}
.grid_view_title {
  font-weight: 500 !important;
  font-size: 17px !important;
}
.grid_view_title span {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.grid_wrapper {
  display: flex;
  align-items: start;
  padding: 10px !important;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2) !important;
  background-color: #fff !important;
  gap: 10px;
  border-radius: 6px;
  height: 200px;
}
.grid_view_details {
  font-weight: 400 !important;
  font-size: 15px !important;
  color: #8e8e93 !important;
  display: flex;
  align-items: center;
}
.grid_view_details svg {
  margin-right: 4px;
}
.edit_delete {
  background-color: var(--default-bg-color) !important;
  padding: 5px 8px;
  border-radius: 8px;
  cursor: pointer;
}
.first_letter {
  background-color: #706d6d !important;
  color: #fff !important;
  padding: 8px !important;
  border-radius: 50%;
}

.TableRowImageContainer {
  display: flex !important;
  align-items: center !important;
  column-gap: 10px !important;
}

.TableContainer {
  overflow-x: scroll;
  width: 100%;
}

.formInputFeild > div,
.selectInput {
  border-radius: 4px !important;
  min-width: 160px !important;
  height: 46px !important;
  max-width: 200px !important;
}
.formInputFeild1 {
  width: 100% !important;
}

.formInputFeild1 > div {
  border-radius: 8px !important;
  min-width: 100% !important;
}

.AddPropertyImages {
  height: 150px !important;
}

.formInputContaine1 {
  width: 100% !important;
  align-items: flex-start !important;
}

.focusedInput fieldset {
  border-color: var(--focused-inputborder) !important;
  border-width: 1px !important;
}

.CheckBoxContainer {
  display: flex !important;
  column-gap: 10px !important;
  align-items: center;
  padding: 10px !important;
}

.checked > svg {
  color: var(--white-color) !important;
}

.checkboxText {
  color: var(--white-color) !important;
}

.CheckBoxActiveSelectAllContainer {
  background-color: var(--default-bg-color) !important;
  color: var(--white-color) !important;
}

.InputLabel {
  font-size: 14px !important;
  top: -7px !important;
}

.focuedValueLable {
  top: 0px !important;
  background: white;
  padding: 0 8px;
  color: var(--focused-inputborder) !important;
}

.filterHeaderdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.clearFilterBtn {
  color: var(--default-bg-color) !important;
  cursor: pointer !important;
}

.datePicker1 {
  position: relative;
}

.datePickerCalendarIcon {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

.dataInput > div {
  width: 255px !important;
}

.dataInputTwo > div {
  width: 275px !important;
  height: 46px !important;
}
