.guestHeaderParentDiv {
    position: sticky;
}

.guestBodyParentDiv {
    position: sticky;
    height: calc(100vh - 60px);
}

.guestHeaderMainDiv {
    display: flex;
    align-items: center;
    padding: 0 10%;
    height: 80px !important;
    box-shadow: 0px 1px 11.699999809265137px 0px #00000026;
}

.guestHeaderLogo {
    height: 45px;
}

.sideNavBarParent {
    height: 100vh;
    background: var(--default-bg-color);
    position: fixed;
    overflow-y: scroll;
    width: 240px;
}

.sideNavBarParent::-webkit-scrollbar {
    display: none;
}

.defaultHeaderMainDiv {
    padding: 0 2% !important;
    justify-content: space-between;
}

.toggledSidebarParent {
    width: 98px !important;
}

.headerParent {
    position: fixed;
    left: 240px;
    box-shadow: 0px 2px 8px 0px rgba(0, 56, 99, 0.20);
    width: calc(100% - 240px);
    z-index: 99;
}

.MainBodyChildDiv {
    position: fixed;
    top: 80px;
    left: 240px;
    width: calc(100% - 240px);
    height: calc(100% - 80px);
    padding: 20px;
    background: var(--default-child-bg-color);
    overflow-y: scroll;
}

.MainBodyChildDiv::-webkit-scrollbar {
    display: none;
}

.toggledHeaderParent,
.toggledMainBodyChildDiv {
    width: calc(100% - 98px);
    left: 98px;
}

.sidenavLogoImage {
    display: block;
}

.imageLogo {
    margin: auto;
    margin-top: 10px;
    width: 151.85px;
    height: 44.6px;
    margin-left: 25px
}

.smallImageLogo {
    height: 40px;
    margin: auto;
    margin-top: 10px;
}

.sidenavListMainCointainer {
    position: relative !important;
}

.linkburron {
    text-transform: none !important;
    color: var(--white-color) !important;
    display: flex !important;
    margin-left: 5px !important;
    min-height: max-content;
    padding: 10px !important;
    width: calc(100% - 5px);
    justify-content: flex-start !important;
    height: 48px;
    padding-left: 25px !important;
}

.navBarIcon {
    margin-right: 10px;
}

.nabBarContainer li {
    list-style-type: none;
    width: 100%;
}

.nabBarContainer a {
    text-decoration: none;
    height: 48px;
}

.activebtn li {
    height: 48px !important;
    border-width: 4px 0px 4px 4px !important;
    border-color: transparent transparent transparent var(--active-border) !important;
    border-style: solid;
}

.activebtn button {
    height: 40px !important;
    background-color: var(--selected-menu-bg) !important;
}

.ToggledButton {
    margin-left: 5px !important;
    justify-content: flex-start !important;
    width: calc(100% - 5px);
    padding-left: 25px !important;
}

.ToggledButton span {
    margin-right: 0 !important;
}

.headerMainText {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    letter-spacing: 0.10000000149011612px !important;
    text-align: left;
    text-transform: capitalize !important;
    color: var(--default-bg-color);
}

.headerSubText {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    letter-spacing: 0em !important;
    text-align: left;
    color: var(--header-sub-text);
}

.defaultHeaderLeftDiv {
    cursor: pointer !important;
}

.defaultHeaderRightDiv {
    display: flex;
    column-gap: 20px !important;
}

.notificationIcon {
    min-width: unset !important;
    padding: 0 !important;
    position: relative;
}

.headerRightBtns {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 5px 7px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 21px !important;
    letter-spacing: -0.022em !important;
    color: var(--default-bg-color) !important;
    text-transform: none !important;
    border-color: var(--default-bg-color) !important;
    padding-right: 20px !important;
}

.logoutBtnContainer {
    position: absolute;
    bottom: 10px;
}
.notification_count{
    position: absolute;
    top: 1px;
    right: -6px;
    background: var(--default-bg-color) !important;
    color: #fff;
    border-radius: 50%;
    padding: 0 7px;
    font-size: 11px;
}