body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SFPRO";
  font-style: normal;
  font-weight: normal;
  src: local("SFPRO"),
    url("./Assets/Fonts/SFPRODISPLAYREGULAR.OTF") format("woff");
}

@font-face {
  font-family: "Abel";
  font-style: normal;
  font-weight: normal;
  src: local("Abel"),
    url("./Assets/Fonts/FontsFree-Net-Abel-Regular.ttf") format("woff");
}

@font-face {
  font-family: "ABeeZee";
  font-style: normal;
  font-weight: normal;
  src: local("ABeeZee"),
    url("./Assets/Fonts/ABeeZee-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Tiro Devanagari Hindi";
  font-style: normal;
  font-weight: normal;
  src: local("Tiro Devanagari Hindi"),
    url("./Assets/Fonts/Tiro-Devanagari-Hindi-Regular.ttf") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: local("Roboto"), url("./Assets/Fonts/Roboto-Regular.ttf") format("woff");
}

* {
  font-family: "SFPRO" !important;
}
.my-field .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px !important;
  background-color: #fff !important;
}
.my-field
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--language-secondary-color) !important;
}
.my-field .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0 !important;
}
.select-field p {
  margin: 0 !important;
  min-width: 130px !important;
}

.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 200px !important;
}

/* .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  background-color: var(--default-bg-color) !important;
  color: #fff;
} */
.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected{
  background-color: var(--default-bg-color) !important;
  color: #fff !important;
}
.MuiButtonBase-root.MuiMenuItem-root{
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between;  
}
.customize-select {
  display: flex !important;
  /* flex-direction: row !important; */
  justify-content: start !important;  
}
.checked + span {
  color: var(--white-color) !important;
}

.d-flex {
  display: flex !important;
  align-items: center !important;
}
.d-flex-between {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}
.property_pagination .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  /* background-color: #A0AEC0 !important; */
  color: var(--default-bg-color) !important;
  font-weight: 600 !important;
}

.custom-placeholder::placeholder {
  color: rgba(0, 0, 0, 0.3); 
}

.custom-placeholder:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.custom-placeholder::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}