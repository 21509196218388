:root {
  --default-bg-color: #093f40;
  --default-bg-color-1: #093f400d;
  --text-color-1: #2e5152;
  --white-color: #ffffff;
  --focused-inputborder: #a4d037;
  --error-color: #f44336;
  --selected-menu-bg: #186769;
  --active-border: #bbe15b;
  --header-sub-text: #001540;
  --default-child-bg-color: #f0f2f4;
  --default-border-color: #dfdfdf;
  --default-btn-text-color: #878790;
  --default-header-row-color: #cff8f2;
  --default-table-header-color: #f0f2f4;
  --sub-heading-color: #000000de;
  --sub-checkbox-btn-color: #eeeff2;
  --defaullt-black: #000000;
  --upload-image-note-text-color: #2d2d2d;
  --default-header-row-color: #cff8f2;
  --default-table-header-color: #f0f2f4;
}

.inner-select .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
}
.inner-select .MuiInputBase-input.MuiOutlinedInput-input{
  min-width: 75px !important;
  width: auto !important;
  border-left: 1px solid rgba(0, 0, 0, 0.2) ;
  border-radius: 0 !important;
}
.inner-select .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select{
  padding: 8px !important;
}
.dropdown-select .MuiInputAdornment-root > div {
 margin-right: -13px !important;
}
.my-autocomplete > div{
  padding-top: 3.5px !important;
  padding-bottom: 3.5px !important;
}
.my-autocomplete label{
  margin-top: -5px;
}
.my-autocomplete-focus label{
  margin-top: 0px !important; 
}
.form-c label{
  margin-top: 3px !important;
}
.selection .MuiButtonBase-root.MuiCheckbox-root.Mui-checked{
  color: #fff !important;
}
.country-code .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 10px !important;
  min-width: 62px !important;
  width: auto !important;
  border-radius: 0 !important;
}
.country-code fieldset{
  border: none !important;
}
.custom-placeholder::placeholder {
  color: rgba(0, 0, 0, 0.3); 
}

.custom-placeholder:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.custom-placeholder::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.optional{
  font-size: 16px;
  margin-left: 5px;
  color: rgba(0,0,0,0.5);
}